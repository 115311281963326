import { useState, useEffect, useRef } from "react";

import { useRouter } from "next/router";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";

import { getGameCitySlug, getPhoneDigits } from "../lib/content";
import { useCity } from "../lib/context/citycontext";
import {
    addToWaitingList,
    enrollToGame,
    fetchEnrollmentPaymentInfo,
    fetchEnrollmentProps,
    fetchUserEnrollments,
} from "../lib/api/games";
import { gtmOnOpenEnrollmentModal, gtmOnEnrollment, gtmOnSocialEnrollment } from "../lib/api/gtm";
import FormControlFeedback from "./controls/formcontrolfeedback";
import PrivacyNote from "./privacynote";
import SubscriptionModal from "./subscriptionmodal";
import { isPhoneValid } from "../lib/validation";
import { useUserEnrollments } from "../lib/context/userenrollmentscontext";
import AuthModal from "./authmodal";
import RequestSuccessModal from "./requestsuccessmodal";
import PhoneNumberInput from "./controls/phonenumberinput";
import { isMeet4USite } from "../lib/coreutils";

const MAX_PLAYERS_TO_ENROLL = 10;
const numFriendsStr = [
    "одного",
    "двоих",
    "троих",
    "четверых",
    "пятерых",
    "шестерых",
    "семерых",
    "восьмерых",
    "девятерых",
    "десятерых",
];

function EnrollmentErrorRow({ field, fieldPrefix, error }) {
    return (
        <div className="text-danger">
            {fieldPrefix}
            {field}: {Array.isArray(error) ? error[0] : error}
        </div>
    );
}

function EnrollmentErrors({ errors, fieldPrefix }) {
    return (
        <div className="text-danger">
            {Object.entries(errors).map(([field, value]) => (
                <div key={field}>
                    {field == "players" ? (
                        <>
                            {value.map((playerErrors, index) => (
                                <EnrollmentErrors
                                    key={index}
                                    fieldPrefix={`player${index + 1}.`}
                                    errors={playerErrors}
                                />
                            ))}
                        </>
                    ) : (
                        <EnrollmentErrorRow field={field} fieldPrefix={fieldPrefix} error={value} />
                    )}
                </div>
            ))}
        </div>
    );
}

function EnrollmentErrorsBlock({ responseStatusCode, errors }) {
    const [show, setShow] = useState(false);

    const isErrorStatusCode = responseStatusCode && responseStatusCode >= 400;
    const hasErrorsToShow = errors && Object.keys(errors).length > 0;

    if (!isErrorStatusCode && !hasErrorsToShow) {
        return null;
    }

    const infoBtnStyle = { color: "#fff", background: "#f00" };
    return (
        <div className="text-danger mt-n2 mb-3">
            Пожалуйста, исправьте ошибки ниже:{" "}
            <a tabIndex="0" className="info-button" style={infoBtnStyle} role="button" onClick={() => setShow(!show)}>
                ?
            </a>
            {show && (
                <div className="mt-2">
                    {isErrorStatusCode ? (
                        <div className="text-danger mb-1">Код ответа от сервера: {responseStatusCode}</div>
                    ) : null}
                    {hasErrorsToShow ? <EnrollmentErrors errors={errors} /> : null}
                </div>
            )}
        </div>
    );
}

function EnrollmentModalWrapper({ game, title, gameTeam, onHide, children }) {
    const { city } = useCity();

    const teamName = gameTeam && gameTeam.id != "new" ? gameTeam.name : null;

    return (
        <Modal show centered className="modal--fancy modal--enrollment" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title as="h5">
                    {title || "Запись на игру"}
                    {game && (
                        <span className="game-date">
                            {game.start_time_medium_weekday} ({city.name})
                        </span>
                    )}
                    {game?.with_teams && teamName && <span className="game-date">Команда: &quot;{teamName}&quot;</span>}
                </Modal.Title>
            </Modal.Header>
            {children}
        </Modal>
    );
}

function EnrollmentWarningModal({ game, warning, onHide }) {
    return (
        <EnrollmentModalWrapper game={game} title="Запись недоступна" onHide={onHide}>
            <Modal.Body>
                <div className="enrollment-modal__prohibition-reason">
                    {warning}
                    <div className="mt-3">Пожалуйста, выберите другой стол.</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </EnrollmentModalWrapper>
    );
}

function EnrollmentWaitingListModal({ game, onHide }) {
    const [phone, setPhone] = useState("");
    const [errors, setErrors] = useState({});
    const [addedToList, setAddedToList] = useState(false);

    const onAddToWaitingList = async () => {
        const response = await addToWaitingList(game.id, phone);
        const json = await response.json();
        if (response.ok) {
            setAddedToList(true);
        } else {
            setErrors(json);
        }
    };

    useEffect(() => {
        async function loadPhone() {
            const props = await fetchEnrollmentProps(game);
            setPhone(props.player.phone);
        }
        loadPhone();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (addedToList) {
        return (
            <EnrollmentModalWrapper game={game} title="Лист ожидания" onHide={onHide}>
                <Modal.Body>
                    <div className="enrollment-modal__prohibition-reason">Мы добавили Вас в лист ожидания.</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={onHide}>
                        Хорошо
                    </Button>
                </Modal.Footer>
            </EnrollmentModalWrapper>
        );
    }

    return (
        <EnrollmentModalWrapper game={game} title="Лист ожидания" onHide={onHide}>
            <Modal.Body>
                <div className="enrollment-modal__prohibition-reason mb-4">
                    Укажите свой телефон. Если за игровым столом освободится место, мы вас оповестим.
                </div>
                <Form>
                    <Form.Group className="input-wrapper">
                        <PhoneNumberInput
                            phone={phone}
                            isInvalid={errors.phone || errors.game}
                            required={true}
                            onChange={setPhone}
                            size="lg"
                        />
                        <FormControlFeedback errors={errors.phone} />
                        <FormControlFeedback errors={errors.game} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onAddToWaitingList}>
                    В лист ожидания
                </Button>
            </Modal.Footer>
        </EnrollmentModalWrapper>
    );
}

function EnrollmentProhibitedModal({ game, onSwitchToOffsiteEvent, onHide }) {
    const [showWaitingListModal, setShowWaitingListModal] = useState(false);

    const title = game.prohibition_reason ? game.prohibition_reason.title : "Стол заполнен";
    const description = game.prohibition_reason
        ? game.prohibition_reason.description.replace("{city}", getGameCitySlug(game))
        : "За данным столом забронированы уже все места.<br />" +
        "Пожалуйста, выберите другой стол или запишитесь в лист ожидания.<br />" +
        "Если появится свободное место - вам придёт уведомление.";

    const onDescriptionClick = (e) => {
        const href = e.target.getAttribute("href");
        if (href && href.indexOf("order-offsite-event") !== -1) {
            e.preventDefault();
            onSwitchToOffsiteEvent();
        }
    };

    if (showWaitingListModal) {
        return <EnrollmentWaitingListModal game={game} onHide={onHide} />;
    }

    return (
        <EnrollmentModalWrapper game={game} title={title} onHide={onHide}>
            <Modal.Body>
                <div
                    className="enrollment-modal__prohibition-reason"
                    dangerouslySetInnerHTML={{ __html: description }}
                    onClick={onDescriptionClick}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => setShowWaitingListModal(true)}>
                    В лист ожидания
                </Button>
            </Modal.Footer>
        </EnrollmentModalWrapper>
    );
}

function EnrollmentSuccessModal({ game, administratorNoteOnSuccess, paymentAmount, paymentUrl, onHide }) {
    return (
        <EnrollmentModalWrapper game={game} onHide={onHide}>
            <Modal.Body>
                <div className="mb-4">
                    <h4>Вы успешно записались на игру.</h4>
                    {administratorNoteOnSuccess && <h5>Наш администратор свяжется с Вами.</h5>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                {paymentAmount && paymentUrl ? (
                    <a href={paymentUrl} className="btn btn-primary">
                        Оплатить <b>{paymentAmount}₽</b> онлайн
                    </a>
                ) : (
                    <Button variant="primary" onClick={onHide}>
                        Закрыть
                    </Button>
                )}
            </Modal.Footer>
        </EnrollmentModalWrapper>
    );
}

function CodeWordRequestSuccessModal({ game, onHide }) {
    return (
        <EnrollmentModalWrapper game={game} title="Кодовое слово" onHide={onHide}>
            <Modal.Body>
                <div className="mb-4">
                    <h4>Заявка на получение кодового слова отправлена.</h4>
                    <h5>Если организатор решит принять Вас в игру, то Вам придёт сообщение.</h5>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </EnrollmentModalWrapper>
    );
}

function TeamCodeWordRequestSuccessModal({ game, teamName, onHide }) {
    return (
        <EnrollmentModalWrapper game={game} title="Кодовое слово" onHide={onHide}>
            <Modal.Body>
                <div className="mb-4">
                    <h4>Заявка на получение кодового слова отправлена.</h4>
                    <h5>Если члены команды &quot;{teamName}&quot; решат принять Вас, то они с Вами свяжутся.</h5>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    Закрыть
                </Button>
            </Modal.Footer>
        </EnrollmentModalWrapper>
    );
}

function EnrollmentPriceSelector({ prices, selectedType, onSelect }) {
    if (isMeet4USite()) {
        return null;
    }
    const priceTypes = [
        ["single", "Стоимость на одного", "Я один"],
        ["friends", "Я + друг", "Я + друг"],
        ["corp", "Своей компанией", "Компания"],
    ].filter((type) => prices[type[0]]);
    return (
        <div className="enrollment-modal__price-selector">
            <Nav
                as="ul"
                className="nav nav-pills"
                activeKey={selectedType}
                onSelect={(selectedKey) => onSelect(selectedKey)}
            >
                {priceTypes.map((priceType) => (
                    <Nav.Item as="li" key={priceType[0]}>
                        <Nav.Link eventKey={priceType[0]}>
                            <span className="d-inline-mobile-only">{priceType[2]}</span>
                            <span className="d-inline-desktop-only">{priceType[1]}</span>
                        </Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
        </div>
    );
}

function EnrollmentCorporateSwitches({ prices, price, onSelect }) {
    const switches = [
        ["corp-entity", "Юр. лицо"],
        ["corp-offsite", "Выездная игра"],
    ];

    const [state, setState] = useState({
        "corp-entity": price == "corp-entity",
        "corp-offsite": price == "corp-offsite",
    });

    const setChecked = (field, checked) => {
        const newState = { "corp-entity": false, "corp-offsite": false };
        if (checked) {
            newState[field] = true;
        }
        setState(newState);
        onSelect(checked ? field : "corp");
    };

    return (
        <div className="enrollment-modal__corporate-switches">
            {switches.map(([id, title]) => (
                <div className="form-check form-switch" key={id}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id={`id-${id}`}
                        checked={state[id]}
                        onChange={(e) => setChecked(id, e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor={`id-${id}`}>
                        {title}
                    </label>
                </div>
            ))}
        </div>
    );
}

function EnrollmentPlayer({
    player,
    idx,
    numPlayers,
    paymentInfo,
    enrollmentType,
    errors,
    setPlayerField,
    subscriptionMayApply,
    removePlayer,
}) {
    const canRemove = idx > 1 || (idx > 0 && numPlayers > 2);

    const onRemoveClick = (event) => {
        event.preventDefault();
        if (canRemove) {
            removePlayer(player.key);
        }
    };

    const toggleUseSubscription = (event) => {
        event.preventDefault();
        setPlayerField(player.key, "use_subscription", !player.use_subscription);
    };

    const toggleEnrollForFree = (event) => {
        event.preventDefault();
        setPlayerField(player.key, "enroll_for_free", !player.enroll_for_free);
    };

    const playerErrors = errors.players && errors.players.length > idx ? errors.players[idx] : {};

    const playerPaymentInfo =
        paymentInfo && paymentInfo.player_payment_info && paymentInfo.player_payment_info.length > idx
            ? paymentInfo.player_payment_info[idx]
            : {};

    const useSubscription = player.use_subscription && playerPaymentInfo.games_available;
    const useSubscriptionDisabled = !playerPaymentInfo.games_available;
    const enrollForFree = player.enroll_for_free && playerPaymentInfo.can_enroll_for_free;
    const enrollForFreeDisabled = !playerPaymentInfo.can_enroll_for_free;

    const isFriendEnrollment = enrollmentType == "friends";

    return (
        <>
            <div className="enrollment-modal__player">
                <Form.Group className="input-wrapper">
                    <Form.Control
                        type="text"
                        size="lg"
                        value={player.name}
                        placeholder={idx == 0 ? "Имя" : "Имя друга"}
                        isInvalid={playerErrors.name}
                        required={true}
                        onChange={(e) => setPlayerField(player.key, "name", e.target.value)}
                    />
                    <FormControlFeedback errors={playerErrors.name} />
                </Form.Group>
                <Form.Group className="input-wrapper">
                    <PhoneNumberInput
                        phone={player.phone}
                        isInvalid={playerErrors.phone}
                        required={idx == 0}
                        onChange={(phone) => setPlayerField(player.key, "phone", phone)}
                        size="lg"
                    />
                    <FormControlFeedback errors={playerErrors.phone} />
                </Form.Group>
                {canRemove && (
                    <div className="enrollment-modal__delete-player d-desktop-only">
                        <a href="" title="Удалить друга" onClick={onRemoveClick}>
                            <img src="/img/icons/icon-close-lg.svg" width="22" height="22" alt="Удалить друга" />
                        </a>
                    </div>
                )}
            </div>
            <div className="enrollment-modal__player-price-info">
                {playerPaymentInfo && playerPaymentInfo.price !== undefined && isFriendEnrollment && (
                    <div className="enrollment-modal__player-price">{playerPaymentInfo.price} руб.</div>
                )}
                {!isMeet4USite() && (
                    <>
                        {subscriptionMayApply && (
                            <div className="enrollment-modal__use-subscription">
                                <Form.Check
                                    type="checkbox"
                                    checked={useSubscription}
                                    id={`id-use-subscription-${player.key}`}
                                    disabled={useSubscriptionDisabled}
                                    onChange={toggleUseSubscription}
                                    inline
                                    label="Использовать абонемент"
                                />
                            </div>
                        )}
                    </>
                )}
                {playerPaymentInfo.can_enroll_for_free && (
                    <div className="enrollment-modal__use-subscription">
                        <Form.Check
                            type="checkbox"
                            checked={enrollForFree}
                            id={`id-enroll-for-free-${player.key}`}
                            disabled={enrollForFreeDisabled}
                            onChange={toggleEnrollForFree}
                            inline
                            label="Записать бесплатно"
                        />
                    </div>
                )}
            </div>
            {canRemove && (
                <div className="enrollment-modal__delete-player d-mobile-only">
                    <a href="" title="Удалить друга" onClick={onRemoveClick}>
                        <img src="/img/icons/icon-close-lg.svg" width="22" height="22" alt="Удалить друга" />
                    </a>
                </div>
            )}
        </>
    );
}

function newPlayerKey() {
    return Math.floor(Math.random() * 1000000000);
}

function EnrollmentPlayerList({
    price,
    players,
    paymentInfo,
    setPlayers,
    subscriptionMayApply,
    refreshPaymentInfo,
    errors,
    canAddPlayer,
}) {
    const addPlayer = (event) => {
        event && event.preventDefault();
        setPlayers((players) => {
            const newPlayers = [
                ...players,
                { name: "", phone: "", use_subscription: true, enroll_for_free: false, key: newPlayerKey() },
            ];
            setTimeout(() => {
                refreshPaymentInfo(price, newPlayers);
            }, 0);
            return newPlayers;
        });
    };

    const removePlayer = (key) => {
        setPlayers((players) => {
            const newPlayers = players.filter((player) => player.key != key);
            refreshPaymentInfo(price, newPlayers);
            return newPlayers;
        });
    };

    const setPlayerField = (key, field, value) => {
        setPlayers((players) => {
            let shouldRefresh = false;
            const newPlayers = players.map((player) => {
                if (player.key == key) {
                    if (field == "phone") {
                        if (isPhoneValid(player[field]) != isPhoneValid(value)) {
                            shouldRefresh = true;
                        } else if (isPhoneValid(value) && value != player[field]) {
                            shouldRefresh = true;
                        }
                    } else if (field == "name") {
                        shouldRefresh = true;
                    } else if (field == "use_subscription") {
                        shouldRefresh = true;
                    } else if (field == "enroll_for_free") {
                        shouldRefresh = true;
                    }
                    player[field] = value;
                }
                return player;
            });
            if (shouldRefresh) {
                refreshPaymentInfo(price, newPlayers);
            }
            return newPlayers;
        });
    };

    const playersToShow = canAddPlayer ? players : players.slice(0, 1);

    return (
        <>
            {playersToShow.map((player, idx) => (
                <EnrollmentPlayer
                    player={player}
                    idx={idx}
                    numPlayers={players.length}
                    paymentInfo={paymentInfo}
                    enrollmentType={price}
                    errors={errors}
                    setPlayerField={setPlayerField}
                    subscriptionMayApply={subscriptionMayApply}
                    removePlayer={removePlayer}
                    key={player.key}
                />
            ))}
            {canAddPlayer && players.length < MAX_PLAYERS_TO_ENROLL && (
                <div className="enrollment-modal__add-player">
                    +{" "}
                    <a href="" onClick={addPlayer}>
                        Добавить друга
                    </a>
                </div>
            )}
        </>
    );
}

function DiscountedPriceNote({ price, discountedPrice, usedUp }) {
    return (
        <>
            <span>
                <del>{price}</del>
                <span className="rouble">₽</span>
            </span>{" "}
            <span className="discount-price">
                {discountedPrice}
                <span className="rouble">₽</span>
            </span>{" "}
            за игру{usedUp}
        </>
    );
}

function BalancePriceNote({ price, priceByBalance, balancePaymentDiscount, forWhat }) {
    return (
        <>
            <span>
                <del>{price}</del>
                <span className="rouble">₽</span>
            </span>{" "}
            <span className="discount-price">
                {priceByBalance}
                {balancePaymentDiscount ? <span className="fw-normal"> (-{balancePaymentDiscount}%)</span> : ""}
                <span className="rouble"> бонусов</span>
            </span>{" "}
            за {forWhat || "игру"}
        </>
    );
}

function EnrollmentPriceNote({ currentPrice, players, paymentInfo, isBalanceUsed, onShowAuthModal }) {
    const showAuthModal = (e) => {
        e.preventDefault();
        onShowAuthModal(paymentInfo.player_payment_info[0].phone);
    };

    switch (currentPrice) {
        case "single":
            const playerPaymentInfo = paymentInfo.player_payment_info[0];
            if (isBalanceUsed) {
                return (
                    <BalancePriceNote
                        price={paymentInfo.total_price}
                        priceByBalance={paymentInfo.total_price_by_balance}
                        balancePaymentDiscount={paymentInfo.balance_payment_discount}
                    />
                );
            } else if (playerPaymentInfo.games_available && playerPaymentInfo.price === 0) {
                const gamesLeft = playerPaymentInfo.games_available - 1;
                return (
                    <>
                        Спишется <span>1</span> игра из абонемента (останется {gamesLeft})
                    </>
                );
            } else if (playerPaymentInfo.price === 0 && playerPaymentInfo.games_available === 0) {
                return <span>Бесплатно</span>;
            } else {
                let usedUp = "";
                if (playerPaymentInfo.free_participation_used_up) {
                    usedUp = (
                        <>
                            <br />
                            <small>Количество бесплатных мест за игровым столом закончилось</small>
                        </>
                    );
                } else if (playerPaymentInfo.login_to_use_subscription) {
                    usedUp = (
                        <>
                            <br />
                            <small>
                                Чтобы использовать абонемент,{" "}
                                <a href="#" onClick={showAuthModal}>
                                    войдите на сайт
                                </a>
                            </small>
                        </>
                    );
                } else if (playerPaymentInfo.subscription_used_up && !playerPaymentInfo.games_available) {
                    usedUp = (
                        <>
                            <br />
                            <small>(Ваш абонемент закончился)</small>
                        </>
                    );
                }
                if (playerPaymentInfo.coupon_price === 0) {
                    return <DiscountedPriceNote price={playerPaymentInfo.price} discountedPrice={0} usedUp={usedUp} />;
                } else if (playerPaymentInfo.coupon_price && playerPaymentInfo.coupon_price < playerPaymentInfo.price) {
                    return (
                        <DiscountedPriceNote
                            price={playerPaymentInfo.price}
                            discountedPrice={playerPaymentInfo.coupon_price}
                            usedUp={usedUp}
                        />
                    );
                } else if (
                    playerPaymentInfo.promocode_price &&
                    playerPaymentInfo.promocode_price < playerPaymentInfo.price
                ) {
                    return (
                        <DiscountedPriceNote
                            price={playerPaymentInfo.price}
                            discountedPrice={playerPaymentInfo.promocode_price}
                            usedUp={usedUp}
                        />
                    );
                } else {
                    return (
                        <>
                            <span>{playerPaymentInfo.price}</span>₽ за игру{usedUp}
                        </>
                    );
                }
            }
        case "friends":
            const numFriends = players.length < 2 ? 2 : players.length;
            const friendsStr = numFriendsStr[numFriends - 1];
            if (isBalanceUsed) {
                return (
                    <BalancePriceNote
                        price={paymentInfo.total_price}
                        priceByBalance={paymentInfo.total_price_by_balance}
                        balancePaymentDiscount={paymentInfo.balance_payment_discount}
                        forWhat={friendsStr}
                    />
                );
            }
            return (
                <>
                    <span>{paymentInfo.total_price}</span>₽ за {friendsStr}
                </>
            );
        default:
            return (
                <>
                    <span>{paymentInfo.total_price}</span>₽ за игру своей компанией
                </>
            );
    }
}

function CouponCodeControl({ couponCode, setCouponCode, errors }) {
    const [showCouponCode, setShowCouponCode] = useState(false);
    return (
        <div className="enrollment-modal__coupon w-100">
            {showCouponCode || couponCode ? (
                <Form.Group className="mb-3" controlId="id_coupon">
                    <Form.Label>Промокод:</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Введите промокод"
                        size="lg"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        isInvalid={errors.coupon_code}
                    />
                    <FormControlFeedback errors={errors.coupon_code} />
                </Form.Group>
            ) : (
                <button className="enrollment-modal__coupon__button mb-2" onClick={() => setShowCouponCode(true)}>
                    <span>Промокод</span>
                </button>
            )}
        </div>
    );
}

function GameTeamControl({ gameTeam, teamName, setTeamName, teamCodeWord, setTeamCodeWord, onAskForCodeWord, errors }) {
    const askForCodeWord = (event) => {
        event.preventDefault();
        onAskForCodeWord();
    };

    if (!gameTeam) {
        return null;
    }

    return (
        <div className="mt-1 mb-3">
            <Form.Group className="input-wrapper">
                <Form.Label className="mb-1">Название команды:</Form.Label>

                <Form.Control
                    type="text"
                    size="lg"
                    value={teamName}
                    placeholder="Название команды"
                    isInvalid={errors.team_name}
                    required={true}
                    className="text-center"
                    onChange={(e) => setTeamName(e.target.value)}
                    disabled={gameTeam.id != "new"}
                />
                <FormControlFeedback errors={errors.team_name} />
            </Form.Group>
            {gameTeam.id != 0 && (
                <Form.Group className="input-wrapper">
                    <Form.Label className="mb-1">
                        {gameTeam.id == "new" ? (
                            <>
                                Кодовое слово
                                <br />
                                <span className="text-muted">(если захотите пригласить кого-то в свою команду)</span>:
                            </>
                        ) : (
                            "Кодовое слово:"
                        )}
                    </Form.Label>
                    <Form.Control
                        type="text"
                        size="lg"
                        value={teamCodeWord}
                        placeholder="Кодовое слово"
                        isInvalid={errors.team_code_word}
                        required={true}
                        className="text-center"
                        onChange={(e) => setTeamCodeWord(e.target.value)}
                    />
                    <FormControlFeedback errors={errors.team_code_word} />
                    {gameTeam.id != "new" && (
                        <div className="mt-3">
                            Не знаете кодового слова?
                            <a href="#" className="d-block" onClick={askForCodeWord}>
                                Отправьте запрос команде
                            </a>
                        </div>
                    )}
                </Form.Group>
            )}
        </div>
    );
}

function getPriceTypeFromEnrollmentType(enrollmentType) {
    if (enrollmentType) {
        return enrollmentType.startsWith("corp") ? "corp" : enrollmentType;
    }
    return "single";
}

function EnrollmentApplicationModal({
    game,
    gameTeam,
    initialEnrollmentType,
    onShowAuthModal,
    onSwitchToSubscription,
    onSuccess,
    onEnrollment,
    onCodeWordRequestSuccess,
    onTeamCodeWordRequestSuccess,
    onHide,
}) {
    const router = useRouter();
    const [waitingForBackend, setWaitingForBackend] = useState(false);
    const [enrollmentPrices, setEnrollmentPrices] = useState(null);
    const [enrollmentPhoneDigits, setEnrollmentPhoneDigits] = useState("");
    const [enrollmentTelegram, setEnrollmentTelegram] = useState("");
    const [enrollmentPromocode, setEnrollmentPromocode] = useState("");
    const [codeWordRequired, setCodeWordRequired] = useState(false);
    const [couponMayApply, setCouponMayApply] = useState(false);
    const [gameSubscriptionMayApply, setGameSubscriptionMayApply] = useState(
        game ? game.subscription_may_apply : false
    );
    const [couponCode, setCouponCode] = useState("");
    const [currentPriceType, setCurrentPriceType] = useState(getPriceTypeFromEnrollmentType(initialEnrollmentType));
    const [currentPrice, setCurrentPrice] = useState(initialEnrollmentType || "single");
    const [players, setPlayers] = useState([
        { name: "", phone: "", use_subscription: true, enroll_for_free: false, key: newPlayerKey() },
        { name: "", phone: "", use_subscription: true, enroll_for_free: false, key: newPlayerKey() }, // сразу заводим "друга", показывать или нет - решаем потом
    ]);
    const [paymentInfo, setPaymentInfo] = useState(null);
    const [errors, setErrors] = useState({});
    const [useBalance, setUseBalance] = useState(true);
    const [useDiscount, setUseDiscount] = useState(true);
    const [codeWord, setCodeWord] = useState("");
    const [teamName, setTeamName] = useState(gameTeam?.id == "new" ? "" : gameTeam?.name || "");
    const [teamCodeWord, setTeamCodeWord] = useState("");
    const [warning, setWarning] = useState(null);
    const [freeEnrollmentWarning, setFreeEnrollmentWarning] = useState(null);
    const [showWhatsAppWarning, setShowWhatsAppWarning] = useState(false);
    const [enrollmentResponceStatusCode, setEnrollmentResponseStatusCode] = useState(null);

    const { setUserEnrollments } = useUserEnrollments();

    const refreshPaymentInfo = async (price, players, waitForResult) => {
        const phones = players.map((player) => {
            return {
                phone: player.phone,
                name: player.name,
                use_subscription: player.use_subscription,
                enroll_for_free: player.enroll_for_free,
            };
        });
        if (waitForResult) {
            const paymentInfo = await fetchEnrollmentPaymentInfo(game, price, phones, useDiscount, couponCode);
            setPaymentInfo(paymentInfo);
            setWarning(paymentInfo.warning);
            setFreeEnrollmentWarning(paymentInfo.free_enrollment_warning);
            if (paymentInfo.errors) {
                setErrors(paymentInfo.errors);
            } else {
                setErrors({});
                setEnrollmentResponseStatusCode(null);
            }
        } else {
            fetchEnrollmentPaymentInfo(game, price, phones, useDiscount, couponCode).then((paymentInfo) => {
                setPaymentInfo(paymentInfo);
                setWarning(paymentInfo.warning);
                setFreeEnrollmentWarning(paymentInfo.free_enrollment_warning);
                if (paymentInfo.errors) {
                    setErrors(paymentInfo.errors);
                } else {
                    setErrors({});
                    setEnrollmentResponseStatusCode(null);
                }
            });
        }
    };

    useEffect(() => {
        async function loadNameAndPhone() {
            const props = await fetchEnrollmentProps(game);
            const newPlayers = [
                {
                    name: props.player.name,
                    phone: props.player.phone,
                    use_subscription: true,
                    key: newPlayerKey(),
                },
                { name: "", phone: "", use_subscription: true, key: newPlayerKey() },
            ];
            setPlayers(newPlayers);

            if (currentPrice == "single") {
                setPaymentInfo(props.payment_info);
                setWarning(props.payment_info?.warning);
                setFreeEnrollmentWarning(props.payment_info?.free_enrollment_warning);
            } else {
                refreshPaymentInfo(currentPrice, newPlayers);
            }
            setEnrollmentPrices(props.prices);
            setEnrollmentPhoneDigits(getPhoneDigits(props.phone_enrollment));
            setEnrollmentTelegram(props.telegram_enrollment);
            setEnrollmentPromocode(props.promocode);
            setCodeWordRequired(props.code_word_required);
            setCouponMayApply(props.coupon_may_apply);
            setGameSubscriptionMayApply(props.subscription_may_apply);

            const gameType = game ? game.game_type.name : "";
            const price = props.payment_info ? props.payment_info.total_price : 0;
            gtmOnOpenEnrollmentModal(gameType, price);
        }
        loadNameAndPhone();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isInitialMount = useRef(true);

    useEffect(() => {
        const refresh = async () => {
            refreshPaymentInfo(currentPrice, players);
        };
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useDiscount, couponCode]);

    useEffect(() => {
        if (!errors?.players) {
            if (showWhatsAppWarning) {
                setShowWhatsAppWarning(false);
            }
            return;
        }
        for (let playerError of errors.players) {
            // WARNING: Текст ошибки задаётся в EnrollmentPlayerCreateSerializer
            //          При изменении подправить и там.
            if (playerError.phone && playerError.phone.includes("WhatsApp не найден.")) {
                setShowWhatsAppWarning(true);
                return;
            }
        }
        if (showWhatsAppWarning) {
            setShowWhatsAppWarning(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    if (waitingForBackend) {
        return null;
    }

    if (!enrollmentPrices || !paymentInfo) {
        return null;
    }

    const showUseBalanceCheckbox =
        (currentPrice == "single" || currentPrice == "friends") &&
        !!(paymentInfo.total_price_by_balance && paymentInfo.can_use_balance);
    const isBalanceUsed = useBalance && showUseBalanceCheckbox;

    const submitEnrollForm = async () => {
        const gameId = game ? game.id : null;
        setWaitingForBackend(true);
        let response;
        try {
            const enrollmentType = currentPrice;
            const teamId = gameTeam?.id;
            response = await enrollToGame({
                gameId,
                players,
                enrollmentType,
                useBalance: isBalanceUsed,
                useDiscount,
                codeWord,
                couponCode,
                teamId,
                teamName,
                teamCodeWord,
            });
        } catch (e) {
            console.log(e);
        }
        if (!response) {
            setEnrollmentResponseStatusCode(null);
            setErrors({ game: ["Ошибка работы с сервером. Повторите попытку позже."] });
            setWaitingForBackend(false);
            return;
        }
        setEnrollmentResponseStatusCode(response.status);
        if (response.ok) {
            const json = await response.json();
            gtmOnEnrollment(json);
            if (json.corporate_request_success) {
                onSuccess(true);
                setWaitingForBackend(false);
                return;
            }
            if (json.payment_url) {
                setTimeout(() => {
                    window.location.href = json.payment_url;
                }, 300);
            } else {
                if (onEnrollment) {
                    onEnrollment(game);
                }
                if (game) {
                    onHide();
                    setUserEnrollments(await fetchUserEnrollments());
                    setTimeout(() => {
                        router.reload(window.location.pathname);
                    }, 300);
                } else {
                    onSuccess();
                    setWaitingForBackend(false);
                }
            }
        } else {
            const json = await response.json();
            setErrors(json);
            setWaitingForBackend(false);
        }
    };

    const askForCodeWord = async () => {
        const gameId = game?.id;
        const enrollmentType = currentPrice;
        const askForCodeWord = true;
        const response = await enrollToGame({
            gameId,
            players,
            enrollmentType,
            askForCodeWord,
        });
        const json = await response.json();
        if (response.ok) {
            if (json.ask_for_code_word_success) {
                onCodeWordRequestSuccess();
            }
        } else {
            setErrors(json);
        }
    };

    const askForTeamCodeWord = async () => {
        const gameId = game?.id;
        const enrollmentType = currentPrice;
        const teamId = gameTeam?.id;
        const askForTeamCodeWord = true;
        const response = await enrollToGame({
            gameId,
            players,
            enrollmentType,
            teamId,
            askForTeamCodeWord,
        });
        const json = await response.json();
        if (response.ok) {
            if (json.ask_for_team_code_word_success) {
                onTeamCodeWordRequestSuccess(teamName);
            }
        } else {
            setErrors(json);
        }
    };

    const setCurrentPriceAndRefresh = (price) => {
        setCurrentPrice(price);
        refreshPaymentInfo(price, players);
    };

    const setCurrentPriceAndType = async (price) => {
        await refreshPaymentInfo(price, players, true);
        setCurrentPrice(price);
        setCurrentPriceType(price);
    };

    const enrollUsingSocial = (event) => {
        const href = event.currentTarget.href || "";
        const socialNetwork = href.indexOf("telegram") !== -1 ? "telegram" : "whatsapp";

        const gameType = game ? game.game_type.name : "";
        const price = paymentInfo ? paymentInfo.total_price : 0;
        gtmOnSocialEnrollment(socialNetwork, gameType, price);
    };

    const toggleUseBalance = () => {
        setUseBalance((prevUseBalance) => !prevUseBalance);
    };

    const toggleUseDiscount = () => {
        setUseDiscount((prevUseDiscount) => !prevUseDiscount);
    };

    const firstPlayerPaymentInfo = paymentInfo.player_payment_info[0];
    const userHasSubscriptions = firstPlayerPaymentInfo.games_available;
    const userSubscriptionUsedUp = firstPlayerPaymentInfo.subscription_used_up;
    const showSubscriptionBtn = currentPrice == "single" && userSubscriptionUsedUp && !userHasSubscriptions;
    const canAddPlayer = currentPriceType == "friends";

    const showUseDiscountCheckbox = !!paymentInfo.is_discount_available;
    const showCouponControl = couponMayApply && currentPrice == "single";
    const showPromocodeField =
        enrollmentPromocode && currentPrice == "single" && paymentInfo.player_payment_info[0].promocode_price;

    const subscriptionMayApply =
        (currentPrice == "single" || currentPrice == "friends") && game && gameSubscriptionMayApply && !isMeet4USite();

    if (warning) {
        return <EnrollmentWarningModal game={game} warning={warning} onHide={onHide} />;
    }
    return (
        <EnrollmentModalWrapper game={game} gameTeam={gameTeam} onHide={onHide}>
            <Modal.Body>
                <EnrollmentPriceSelector
                    prices={enrollmentPrices}
                    selectedType={currentPriceType}
                    onSelect={setCurrentPriceAndType}
                />
                <Form>
                    <EnrollmentErrorsBlock errors={errors} responseStatusCode={enrollmentResponceStatusCode} />
                    {errors.game && <Alert variant="danger">{errors.game[0]}</Alert>}
                    <EnrollmentPlayerList
                        price={currentPrice}
                        players={players}
                        paymentInfo={paymentInfo}
                        setPlayers={setPlayers}
                        subscriptionMayApply={subscriptionMayApply}
                        refreshPaymentInfo={refreshPaymentInfo}
                        canAddPlayer={canAddPlayer}
                        errors={errors}
                    />
                </Form>

                <div className="enrollment-modal__price">
                    <EnrollmentPriceNote
                        currentPrice={currentPrice}
                        players={players}
                        paymentInfo={paymentInfo}
                        isBalanceUsed={isBalanceUsed}
                        onShowAuthModal={() => onShowAuthModal(players[0].phone)}
                    />
                </div>

                {currentPriceType == "corp" && (
                    <EnrollmentCorporateSwitches
                        prices={enrollmentPrices}
                        price={currentPrice}
                        onSelect={setCurrentPriceAndRefresh}
                    />
                )}
                {showUseDiscountCheckbox && (
                    <div className="enrollment-modal__use-discount">
                        <Form.Check
                            type="checkbox"
                            checked={useDiscount}
                            id="use-discount"
                            onChange={toggleUseDiscount}
                            inline
                            label="Использовать скидку (невозвратный билет)"
                        />
                    </div>
                )}
                {showUseBalanceCheckbox && (
                    <div className="enrollment-modal__use-balance">
                        <Form.Check
                            type="checkbox"
                            checked={useBalance}
                            id="use-balance"
                            onChange={toggleUseBalance}
                            inline
                            label="Использовать бонусы"
                        />
                    </div>
                )}
                {showSubscriptionBtn && (
                    <Button variant="primary" className="mb-3" onClick={onSwitchToSubscription}>
                        Купить абонемент
                    </Button>
                )}
                {showWhatsAppWarning && (
                    <div className="text-danger mb-3">
                        Пожалуйста, проверьте корректность номера телефона, т.к. на него будет отправлено подтверждение
                        записи (на WhatsApp) и ведущий может попросить показать это подтверждение, чтобы допустить вас
                        до игры.
                    </div>
                )}

                {codeWordRequired && (
                    <div>
                        Игра &quot;своей компанией&quot;, ведите кодовое слово:
                        <div className="d-flex mb-sm-3">
                            <Form.Group className="input-wrapper mt-1 mb-0">
                                <Form.Control
                                    type="text"
                                    size="lg"
                                    value={codeWord}
                                    placeholder="Кодовое слово"
                                    isInvalid={errors.code_word}
                                    required={true}
                                    onChange={(e) => setCodeWord(e.target.value)}
                                />
                                <FormControlFeedback errors={errors.code_word} />
                            </Form.Group>
                        </div>
                    </div>
                )}
                {(currentPrice == "single" || currentPrice == "friends") && game?.with_teams && (
                    <GameTeamControl
                        gameTeam={gameTeam}
                        teamName={teamName}
                        setTeamName={setTeamName}
                        teamCodeWord={teamCodeWord}
                        setTeamCodeWord={setTeamCodeWord}
                        onAskForCodeWord={askForTeamCodeWord}
                        errors={errors}
                    />
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" className="mb-3" onClick={submitEnrollForm}>
                    Записаться
                </Button>

                {freeEnrollmentWarning && (
                    <div className="mt-1 w-100 text-preline text-danger">{freeEnrollmentWarning}</div>
                )}

                {codeWordRequired && game?.organizer && (
                    <>
                        <div className="mt-2">Не знаете кодового слова, но хотите поиграть?</div>
                        <Button variant="primary" className="mt-2 mb-3" onClick={askForCodeWord}>
                            Оставьте заявку
                        </Button>
                    </>
                )}

                {showCouponControl && (
                    <CouponCodeControl couponCode={couponCode} setCouponCode={setCouponCode} errors={errors} />
                )}

                <div className="enrollment-modal__hr">
                    <span>или</span>
                </div>
                <div className="enrollment-modal__social">
                    <a href={enrollmentTelegram} onClick={enrollUsingSocial} target="_blank" rel="noreferrer">
                        <img src="/img/telegram-color.svg" width="32" height="32" alt="" />
                        <span>Запись через Telegram</span>
                    </a>

                    {enrollmentPhoneDigits && enrollmentPhoneDigits.length > 0 && (
                        <a
                            href={`https://wa.me/${enrollmentPhoneDigits}`}
                            onClick={enrollUsingSocial}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/img/whatsapp-color.svg" width="32" height="32" alt="" />
                            <span>Запись через WhatsApp</span>
                        </a>
                    )}
                </div>
                <div className="w-100 m-0">
                    <PrivacyNote />
                </div>
                {showPromocodeField && (
                    <div className="enrollment-modal__promocode enrollment-modal__promocode--applied">
                        <div className="enrollment-modal__player">
                            <Form.Group className="input-wrapper">
                                <Form.Control
                                    type="text"
                                    size="lg"
                                    value={enrollmentPromocode}
                                    placeholder="Ваш промокод"
                                    isInvalid={false}
                                    required={false}
                                    onChange={(e) => setEnrollmentPromocode(enrollmentPromocode)}
                                />
                                <i className="icon-check"></i>
                                <FormControlFeedback errors={""} />
                            </Form.Group>

                            <div className="enrollment-modal__promocode__info">
                                {enrollmentPromocode && (
                                    <div className="d-flex align-items-center">
                                        <div>Промокод применён</div>
                                        <div className="enrollment-modal__promocode__delete d-none">
                                            <a
                                                href="#"
                                                title="Удалить промокод"
                                                onClick={(e) => setEnrollmentPromocode("")}
                                            >
                                                <img
                                                    src="/img/icons/icon-close-lg.svg"
                                                    width="22"
                                                    height="22"
                                                    alt="Удалить промокод"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </Modal.Footer>
        </EnrollmentModalWrapper>
    );
}

export default function EnrollmentModal({
    game,
    gameTeam,
    administratorNoteOnSuccess,
    initialEnrollmentType,
    onHide,
    onEnrollment,
}) {
    const [authModalVisible, setAuthModalVisible] = useState(false);
    const [authModalInitialPhone, setAuthModalInitialPhone] = useState("");
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
    const [gameToEnroll, setGameToEnroll] = useState(game);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isCorporate, setIsCorporate] = useState(false);
    const [isCodeWordRequested, setIsCodeWordRequested] = useState(false);
    const [isTeamCodeWordRequested, setIsTeamCodeWordRequested] = useState(false);
    const [teamName, setTeamName] = useState("");
    const [enrollmentType, setEnrollmentType] = useState(initialEnrollmentType);

    const switchToSubscription = () => {
        setShowSubscriptionModal(true);
    };

    const showAuthModal = (phone) => {
        setAuthModalInitialPhone(phone);
        setAuthModalVisible(true);
    };

    const hideAuthModal = () => {
        setAuthModalVisible(false);
    };

    const switchToOffsiteEvent = () => {
        setIsSuccess(false);
        setGameToEnroll(null);
        setEnrollmentType("corp");
    };

    const onEnrollmentSuccess = (isCorporate) => {
        setIsCorporate(isCorporate);
        setIsSuccess(true);
    };

    const onCodeWordRequestSuccess = () => {
        setIsCodeWordRequested(true);
        setIsSuccess(true);
    };

    const onTeamCodeWordRequestSuccess = (teamName) => {
        setTeamName(teamName);
        setIsTeamCodeWordRequested(true);
        setIsSuccess(true);
    };

    if (showSubscriptionModal) {
        return <SubscriptionModal onHide={onHide} />;
    } else if (gameToEnroll && gameToEnroll.is_enrollment_prohibited) {
        return (
            <EnrollmentProhibitedModal
                game={gameToEnroll}
                onSwitchToOffsiteEvent={switchToOffsiteEvent}
                onHide={onHide}
            />
        );
    } else if (isSuccess) {
        if (isCorporate) {
            return <RequestSuccessModal onHide={onHide} />;
        }
        if (isCodeWordRequested) {
            return <CodeWordRequestSuccessModal onHide={onHide} />;
        }
        if (isTeamCodeWordRequested) {
            return <TeamCodeWordRequestSuccessModal teamName={teamName} onHide={onHide} />;
        }
        return (
            <EnrollmentSuccessModal
                game={gameToEnroll}
                administratorNoteOnSuccess={administratorNoteOnSuccess}
                onHide={onHide}
            />
        );
    } else {
        return (
            <>
                <EnrollmentApplicationModal
                    game={gameToEnroll}
                    gameTeam={gameTeam}
                    initialEnrollmentType={enrollmentType}
                    onShowAuthModal={showAuthModal}
                    onSwitchToSubscription={switchToSubscription}
                    onEnrollment={onEnrollment}
                    onSuccess={onEnrollmentSuccess}
                    onCodeWordRequestSuccess={onCodeWordRequestSuccess}
                    onTeamCodeWordRequestSuccess={onTeamCodeWordRequestSuccess}
                    onHide={onHide}
                />
                {authModalVisible && (
                    <AuthModal initialPhone={authModalInitialPhone} onHide={hideAuthModal} doNotHideOnLogin={true} />
                )}
            </>
        );
    }
}
